import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Hi! I'm Dennis 👋`}</h1>
    <p>{`I'm a web developer, from both Sweden and Kenya.`}</p>
    <p>{`I create websites and online platforms that fuel competitive advantage and revenue growth for your business and brand.`}</p>
    <p>{`My main tools of trade are PHP (Symfony, Drupal), JavaScript (ReactJs, NodeJS), GraphQL, Shopify.`}</p>
    <p>{`I would love to hear from you! You can reach me at `}<a href="https://www.linkedin.com/in/mathengedennis" target="_blank">{`Dennis`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      